<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-data-table
      :headers="headers"
      :items="filteredDesserts"
      class="elevation-1"
      stle
      :options.sync="options"
      :loading-text="$t('table.messages.loading')"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 100],
        showFirstLastPage: false,
        'items-per-page-text': $t('table.messages.items_per_page'),
      }"
    >
      <template slot="no-data">
        {{ $t("table.messages.no_data") }}
      </template>
      <template
        v-slot:[`footer.page-text`]=""
        class="mr-0"
      >
        {{ $t("table.messages.page") }}
        {{ options.page }}
      </template>
      <template v-slot:top>
        <v-container class="pt-5">
          <span class="custom-color-note">{{ $t("messages.listEmployees_titleInput1") }}</span>
          <v-row class="d-flex justify-space-between mt-2">
            <v-col cols="2">
              <v-autocomplete
                v-if="userType !== 3"
                v-model="customer"
                :items="listCustomer"
                :item-text="item => item.code == null ? item.name : item.code +' - '+ item.name"
                item-value="id"
                :label="$t('messages.customerName')"
                color="blue"
              />
              <div
                v-else
              >
                <div>
                  <span>{{ $t('messages.factoryName') }}</span>
                </div>
                <div class="ml-2">
                  {{ profile.name?profile.name:'' }}
                </div>
              </div>
            </v-col>
            <v-col cols="2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    :label="$t('chart.timeUnit')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  type="month"
                  no-title
                  scrollable
                  locale="ja"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    {{ $t('messages.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date)"
                  >
                    {{ $t('messages.ok') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" />
            <v-col cols="1">
              <v-btn
                block
                color="primary"
                @click="seachDocumentStatus"
              >
                <span class="text-h5 font-weight-bold">
                  {{ $t("button.show") }}</span>
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-dialog
                v-model="dialog"
                max-width="1200px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="mb-5 pt-0 pl-10 pr-10 white--text d-flex justify-end"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="font-weight-bold text-h5">
                      {{ $t("button.exportCsv") }}</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-actions>
                    <v-row class="d-flex justify-space-between ml-1 mr-1">
                      <v-col cols="2">
                        <v-autocomplete
                          v-model="popupZaiseki.selected"
                          :items="popupZaiseki.dataProvider"
                          item-value="id"
                          item-text="name"
                          :label="$t('messages.zaiseki')"
                          placeholder="データ表示を選択"
                          color="blue"
                          @change="selectEventPopupZaiseki"
                        />
                      </v-col>
                      <v-col
                        v-if="userType !== 3"
                        cols="2"
                      >
                        <v-autocomplete
                          v-if="
                            !popupZaiseki.selected || popupZaiseki.selected == 1
                          "
                          v-model="popupFactoryNames.selected"
                          :items="listCustomer"
                          :item-text="item => item.code == null ? item.name : item.code +' - '+ item.name"
                          item-value="id"
                          label="工場"
                          placeholder="工場名・コードを入力"
                          color="blue"
                        />
                        <v-autocomplete
                          v-else
                          v-model="popupFactoryNames.selected"
                          :items="listCustomer"
                          :item-text="item => item.code == null ? item.name : item.code +' - '+ item.name"
                          item-value="id"
                          label="工場"
                          placeholder="工場名・コードを入力"
                          color="blue"
                          multiple
                        />
                      </v-col>
                      <v-col
                        v-else
                        cols="2"
                      >
                        <div>
                          <div>
                            <span>{{ $t('messages.factoryName') }}</span>
                          </div>
                          <div class="ml-2">
                            {{ profile.name?profile.name:'' }}
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <v-menu
                          ref="popupmenu"
                          v-model="popupMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateTime"
                              :label="$t('chart.timeUnit')"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-if="
                              !popupZaiseki.selected ||
                                popupZaiseki.selected == 1
                            "
                            v-model="popupDate"
                            type="month"
                            no-title
                            locale="ja"
                            scrollable
                          >
                            <v-spacer />
                            <v-btn
                              text
                              color="primary"
                              @click="menu = false"
                            >
                              {{ $t('messages.cancel') }}
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.popupmenu.save(popupDate)"
                            >
                              {{ $t('messages.ok') }}
                            </v-btn>
                          </v-date-picker>
                          <v-date-picker
                            v-else
                            v-model="popupDateMulti"
                            type="month"
                            no-title
                            locale="ja"
                            scrollable
                            multiple
                          >
                            <v-spacer />
                            <v-btn
                              text
                              color="primary"
                              @click="menu = false"
                            >
                              {{ $t('messages.cancel') }}
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.popupmenu.save(popupDateMulti)"
                            >
                              {{ $t('messages.ok') }}
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          class="mt-2"
                          block
                          color="primary"
                          @click="seachDocumentStatusExport"
                        >
                          <span class="text-h5 font-weight-bold">
                            {{ $t("messages.preview") }}
                          </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                  <v-card-text>
                    <v-row>
                      <datatable-export-csv
                        :headers="headerPopupExportZaiseki"
                        :items="exportData"
                      >
                        <template
                          v-slot:[`footer.page-text`]=""
                          class="mr-0"
                        >
                          {{ $t("table.messages.page") }}
                          {{ options.page }}
                        </template>
                        <template v-slot:[`footer.prepend`] />
                      </datatable-export-csv>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row class="d-flex justify-center mb-3">
                      <v-col cols="3">
                        <json-excel
                          :data="exportData"
                          :fields="headerExport"
                          :name="exportHeadText + new Date().getTime()"
                        >
                          <v-btn
                            block
                            color="primary"
                            type="submit"
                          >
                            <span class="text-h5 font-weight-bold">
                              {{ $t("messages.exportCSV") }}</span>
                          </v-btn>
                        </json-excel>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          block
                          color="grey"
                          @click="close"
                        >
                          <span class="text-h5 font-weight-bold white--text">{{
                            $t("messages.close")
                          }}</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="3">
              <v-dialog
                v-if="dialogEmployee == true"
                v-model="dialogEmployee"
                max-width="1200px"
              >
                <v-card>
                  <v-card-text>
                    <v-row class="text-center pt-5">
                      <v-col cols="3">
                        <label for="id">{{
                          $t("table.headers.controlNumber")
                        }}</label>
                        <span
                          id="id"
                          class="black--text"
                        >
                          <strong>
                            {{ dialogCheckDocsEmployee.parttimeEmployeeManageId || '' }}
                          </strong>
                        </span>
                      </v-col>
                      <v-col cols="3">
                        <label for="name">{{
                          $t("table.titles.fullName")
                        }}</label>
                        <span
                          id="name"
                          class="black--text"
                        >
                          <strong>
                            {{ dialogCheckDocsEmployee.parttimeEmployeeName || '' }}
                          </strong>
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text>
                    <div class="pr-7 pl-7 pb-7 mr-10 ml-10 card-text-contents">
                      <v-row>
                        <v-col
                          cols="3"
                          style="padding-top: 2%;"
                        >
                          <span>{{ $t("table.headers.visaType") }}</span>
                          <v-chip
                            class="ma-2 black--text"
                            color="primary"
                            outlined
                          >
                            {{ dialogCheckDocsEmployee.visaType }}
                          </v-chip>
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox
                            v-model="dialogCheckDocsEmployee.contracted"
                            :label="$t('checkbox.contracFactoryCheck')"
                            hide-details
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="8">
                          <v-data-table
                            :headers="headersDialogCheckDocsEmployee"
                            :items="itemsDialogCheckDocsEmployee"
                            item-key="detail"
                            class="elevation-1"
                            :loading-text="$t('table.messages.loading')"
                            :loading="!itemsDialogCheckDocsEmployee.length"
                            stle
                            hide-default-footer
                            disable-pagination
                          >
                            <template v-slot:[`item.isFulfiled`]="{ item }">
                              <v-simple-checkbox
                                v-model="item.isFulfiled"
                                color="#0F56B3"
                                :value="item.detail"
                                @click="eventCheckbox(item)"
                              />
                            </template>
                            <template v-slot:[`item.note`]="props">
                              <v-text-field
                                v-model="props.item.note"
                                :counter="100"
                                maxlength="100"
                              />
                            </template>
                          </v-data-table>
                        </v-col>
                        <v-col
                          cols="3"
                          class="ml-12"
                        >
                          <v-row class="red--text">
                            {{ new Date(dialogCheckDocsEmployee.timestamp).getFullYear() + '年' + (new Date(dialogCheckDocsEmployee.timestamp).getMonth() + 1) + '月の状態' }}
                          </v-row>
                          <v-row>
                            <v-checkbox
                              v-model="dialogCheckDocsEmployee.isFulfilled"
                              :label="$t('checkbox.enough_documents')"
                              hide-details
                              @change="checkAll"
                            />
                          </v-row>
                          <v-row>
                            <v-checkbox
                              v-model="dialogCheckDocsEmployee.isRetented"
                              :label="$t('checkbox.salary_not_retained')"
                              hide-details
                            />
                          </v-row>
                          <v-row>
                            <v-checkbox
                              v-model="dialogCheckDocsEmployee.isChecked"
                              :label="$t('checkbox.checked')"
                              hide-details
                            />
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-row class="d-flex justify-center mb-3">
                      <v-col cols="3">
                        <v-btn
                          block
                          color="primary"
                          type="submit"
                          @click="saveCheckDocs"
                        >
                          <span class="text-h5 font-weight-bold">
                            {{ $t("button.save") }}</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          block
                          color="grey"
                          @click="closeDialogEmployee"
                        >
                          <span class="text-h5 font-weight-bold white--text">{{
                            $t("messages.close")
                          }}</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <br>
          <span class="custom-color-note">{{ $t("messages.listEmployees_titleInput2") }}</span>
          <v-row class="d-flex justify-space-between">
            <v-col cols="2">
              <v-select
                v-model="filters.isFulfilled"
                :items="documentsCheck"
                :label="$t('messages.listEmployees_documentsCheck')"
                item-text="text"
                item-value="value"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="filters.isRetented"
                :items="salaryRetention"
                :label="$t('messages.listEmployees_salaryRetention')"
                item-text="text"
                item-value="value"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="filters.isChecked"
                :items="listChecked"
                :label="$t('messages.listEmployees_checked')"
                item-text="text"
                item-value="value"
              />
            </v-col>
            <v-col cols="3">
              <div class="align-self-center">
                <v-text-field
                  v-model="filters.id"
                  :label="$t('messages.listEmployees_partTimeEmployee')"
                  :placeholder="$t('placeholder.enterControlNumber')"
                  outlined
                  @input="filterSearch"
                />
              </div>
            </v-col>
          </v-row>
          <br>
        </v-container>
      </template>
      <template v-slot:[`item.index`]="props">
        {{ getIndex(props.index) }}
      </template>
      <template
        v-slot:[`item.parttimeEmployeeManageId`]="props"
        no-gutter
      >
        <td @click="openDialogEmployee(props)">
          <a>{{ props.item.parttimeEmployeeManageId }}</a>
        </td>
      </template>
      <template
        v-slot:[`item.isRetented`]="props"
        no-gutter
      >
        <v-simple-checkbox
          v-model="props.item.isRetented"
          color="#0F56B3"
          disabled
        />
      </template>
      <template
        v-slot:[`item.isFulfilled`]="props"
        no-gutter
      >
        <v-simple-checkbox
          v-model="props.item.isFulfilled"
          color="#0F56B3"
          disabled
        />
      </template>
      <template
        v-slot:[`item.isChecked`]="props"
        no-gutter
      >
        <v-simple-checkbox
          v-model="props.item.isChecked"
          color="#0F56B3"
          disabled
        />
      </template>
      <template v-slot:[`footer.prepend`] />
    </v-data-table>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t("messages.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import DatatableExportCsv from '@/components/DatatableExportCsv.vue'
  import JsonExcel from 'vue-json-excel'
  import { omit } from 'lodash'

  export default {
    name: 'DocumentStatus',
    components: {
      DatatableExportCsv,
      JsonExcel,
    },
    data: () => ({
      snackbarMessage: '',
      snackbar: false,
      snackbarCloseInterval: null,
      options: {},
      headers: [],
      items: [],
      headerPopupExportZaiseki: [],
      headerExport: [],
      exportData: [],
      exportHeadText: '',
      editedIndex: -1,
      totalItems: 0,
      loading: false,
      loadingDetail: false,
      docIsActive: null,
      dialog: false,
      listCustomer: [],
      customer: 0,
      allType: [],
      salaryRetention: [],
      listChecked: [],
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      filters: {
        id: '',
        isFulfilled: '',
        isChecked: '',
        isRetented: '',
      },
      popupMenu: false,
      popupZaiseki: {
        dataProvider: [],
        selected: '',
      },
      popupFactoryNames: {
        selected: '',
      },
      popupDate: new Date().toISOString().substr(0, 7),
      popupDateMulti: [],
      listCheck: [],
      dialogEmployee: false,
      headersDialogCheckDocsEmployee: [],
      itemsDialogCheckDocsEmployee: [],
      dialogCheckDocsEmployee: {},
      organizationRules: [
        (v) =>
          (v && v.length <= 100) ||
          '状態を入力する（100文字以内)',
      ],
      userType: '',
    }),
    computed: {
      ...get('documentStatus', [
        'documentStatus',
        'documentStatuses',
        'message',
        'status',
        'statusDetail',
        'error',
        'totalCount',
        'customers',
        'documentStatusesExport',
      ]),
      ...get('user', ['profile']),
      dateTime () {
        return this.popupZaiseki.selected === 1
          ? this.popupDate
          : this.popupDateMulti
      },
      filteredDesserts () {
        const conditions = []
        if (this.filters.id) {
          conditions.push(this.filterSearch)
        }
        if (this.filters.isFulfilled) {
          conditions.push(this.filterDocumentsCheck)
        }
        if (this.filters.isRetented) {
          conditions.push(this.filterSalary)
        }
        if (this.filters.isChecked) {
          conditions.push(this.filterChecked)
        }
        if (conditions.length > 0) {
          return this.items.filter((item) => {
            return conditions.every((condition) => {
              return condition(item)
            })
          })
        }
        return this.items
      },
    },
    watch: {
      error (value) {
        this.showSnackBar(value)
      },
      documentStatuses (value) {
        this.$set(this, 'items', value)
        window.scrollTo(0, document.body.scrollHeight)
      },
      documentStatus (value) {
        this.$set(this, 'dialogCheckDocsEmployee', value)
        const itemsArr = []
        itemsArr.push(JSON.parse(value.detail))
        itemsArr.map((i) => {
          Object.entries(i).map(a => {
            this.itemsDialogCheckDocsEmployee.push({
              detail: a[0],
              isFulfiled: a[1].isFulfiled,
              note: a[1].note,
            })
          })
        })
        this.loadingDetail = false
      },
      documentStatusesExport (value) {
        this.$set(this, 'exportData', value)
      },
      status (value) {
        this.loading = value === 'loading'
      },
      totalCount (value) {
        this.totalItems = value
      },
      dialog (val) {
        val || this.close()
      },
      dialogEmployee (val) {
        val || this.closeDialogEmployee()
      },
      dialogCheckDocsEmployee (val) {
        val || this.closeDialogEmployee()
      },
      customers (value) {
        this.$set(this, 'listCustomer', value.data.customers)
      },
      customer () {
        if (this.userType === 3) {
          this.seachDocumentStatus()
        }
      },
    },
    created () {
      this.$store.dispatch('user/getProfile')
      this.userType = JSON.parse(
        JSON.parse(localStorage.getItem('user')).data,
      ).type

      if (this.userType === 3) {
        this.customer = JSON.parse(
          JSON.parse(localStorage.getItem('user')).data,
        ).typeCorrespondId
      }
      this.headers = [
        {
          text: this.$t('table.headers.index'),
          align: 'center',
          value: 'index',
          class: 'font-weight-bold',
          width: '6%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.controlNumber'),
          align: 'start',
          value: 'parttimeEmployeeManageId',
          class: 'font-weight-bold',
          width: '12%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.name'),
          align: 'start',
          value: 'parttimeEmployeeName',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.visaType'),
          align: 'start',
          value: 'visaType',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.documentChecked'),
          align: 'start',
          value: 'isFulfilled',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.salaryRetained'),
          align: 'start',
          value: 'isRetented',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.checked'),
          align: 'start',
          value: 'isChecked',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
      ]
      this.headerPopupExportZaiseki = [
        {
          text: this.$t('table.headers.month'),
          align: 'center',
          value: 'month',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$t('messages.customerName'),
          align: 'start',
          value: 'customerName',
          class: 'font-weight-bold',
          width: '12%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.controlNumber'),
          align: 'start',
          value: 'manageId',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.name'),
          align: 'start',
          value: 'parttimeEmployeeName',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.tottalWorkTime'),
          align: 'start',
          value: 'totalWorkhour',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.insufficientDocuments'),
          align: 'center',
          value: 'isChecked',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
      ]
      this.documentsCheck = [
        { text: this.$t('combobox.bulkAll'), value: 'ALL' },
        { text: this.$t('combobox.enough_documents'), value: 'ENOUGH_DOCUMENTS' },
        {
          text: this.$t('combobox.insufficient_document'),
          value: 'INSUFFICIENT_DOCUMENTS',
        },
      ]
      this.salaryRetention = [
        { text: this.$t('combobox.bulkAll'), value: 'ALL' },
        { text: this.$t('combobox.salary_retained'), value: 'SALARY_RETAINED' },
        {
          text: this.$t('combobox.salary_not_retained'),
          value: 'SALARY_NOT_RETAINED',
        },
      ]
      this.listChecked = [
        { text: this.$t('combobox.bulkAll'), value: 'ALL' },
        { text: this.$t('combobox.already_checked'), value: 'ALREADY_CHECKED' },
        { text: this.$t('combobox.checking'), value: 'CHECKING' },
      ]
      this.headersDialogCheckDocsEmployee = [
        {
          text: this.$t('table.headers.requiredDocsList'),
          align: 'center',
          value: 'detail',
          class: 'font-weight-bold',
          sortable: false,
        },
        {
          text: '',
          align: 'center',
          value: 'isFulfiled',
          class: 'font-weight-bold',
          sortable: false,
        },
        {
          text: this.$t('table.headers.Note'),
          align: 'center',
          value: 'note',
          class: 'font-weight-bold',
          sortable: false,
        },
      ]
      this.popupZaiseki.dataProvider = [
        {
          name: this.$t('combobox.enrollment_list'),
          id: 1,
        },
        {
          name: this.$t('combobox.salary_retained'),
          id: 2,
        },
      ]
      this.exportHeadText = '在籍管理,'
      this.headerExport = {
        月: 'month',
        工場: 'customerName',
        管理番号: 'manageId',
        氏名: 'parttimeEmployeeName',
        勤務時間: 'totalWorkhour',
      }
    },
    mounted () {
      if (this.$route.query) {
        if (this.$route.query.success) this.showSnackBar('messages.success')
      }
      if (this.userType !== 3) {
        this.getCustomers()
      }
    },
    methods: {
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      convertArrayToObject (array, key) {
        const innialValue = {}
        return array.reduce((obj, item) => {
          return { ...obj, [item[key]]: omit(item, key) }
        }, innialValue)
      },
      selectEventPopupZaiseki () {
        this.popupFactoryNames.selected = ''
      },
      /**
       * Handler when user input something at the "Filter" text field.
       */
      filterSearch (item) {
        if (item.parttimeEmployeeManageId) {
          return item.parttimeEmployeeManageId.toString().toLowerCase().includes(this.filters.id.toLowerCase())
        }
      },
      filterDocumentsCheck (item) {
        if (this.filters.isFulfilled.trim() === 'ENOUGH_DOCUMENTS') {
          return item.isFulfilled === true
        } else if (this.filters.isFulfilled.trim() === 'INSUFFICIENT_DOCUMENTS') {
          return item.isFulfilled === false
        } else {
          return item
        }
      },
      filterSalary (item) {
        if (this.filters.isRetented.trim() === 'SALARY_RETAINED') {
          return item.isRetented === true
        } else if (this.filters.isRetented.trim() === 'SALARY_NOT_RETAINED') {
          return item.isRetented === false
        } else {
          return item
        }
      },
      filterChecked (item) {
        if (this.filters.isChecked.trim() === 'ALREADY_CHECKED') {
          return item.isChecked === true
        } else if (this.filters.isChecked.trim() === 'CHECKING') {
          return item.isChecked === false
        } else {
          return item
        }
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInterval) {
            clearInterval(this.snackbarCloseInterval)
          }
          this.snackbarCloseInterval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      seachDocumentStatus () {
        var payload = {}
        payload.timestamp = this.date + '-01'
        payload.customerId = this.customer
        this.$store.dispatch('documentStatus/getDocumentStatus', payload)
      },
      getCustomers () {
        this.$store.dispatch('documentStatus/getCustomers')
      },

      close () {
        this.dialog = false
        this.popupFactoryNames.selected = ''
        this.exportData = []
        this.popupDate = ''
        this.popupDateMulti = []
        this.popupZaiseki.selected = ''
      },
      /* Handle Export CSV Popup */
      seachDocumentStatusExport () {
        var timestamp = []
        let customerId = this.popupFactoryNames.selected.toString()
        if (this.userType === 3) {
          customerId = this.customer
        }
        if (this.popupZaiseki.selected === 1) {
          timestamp.push(this.popupDate + '-01')
        } else {
          timestamp = this.popupDateMulti.map((i) => i + '-01')
        }
        var payload = {
          option: this.popupZaiseki.selected,
          customerId: customerId,
          timestamp: timestamp.toString(),
        }
        this.$store.dispatch('documentStatus/getDocumentStatusExport', payload)
      },
      /* Handle Detail Document Status Popup */
      eventCheckbox () {
        var list = []
        if (this.itemsDialogCheckDocsEmployee.length > 0) {
          this.itemsDialogCheckDocsEmployee.map((check) => {
            if (check.isFulfiled === true) {
              list.push(check.detail)
            }
          })
        }
        this.dialogCheckDocsEmployee.listCheck = list.filter(function (
          item,
          pos,
        ) {
          return list.indexOf(item) === pos
        })
        if (
          this.dialogCheckDocsEmployee.listCheck.length ===
          this.itemsDialogCheckDocsEmployee.length
        ) {
          this.dialogCheckDocsEmployee.isFulfilled = true
          this.dialogCheckDocsEmployee.isRetented = true
        }
      },
      checkAll (item) {
        if (item === true) {
          this.itemsDialogCheckDocsEmployee.filter((check) => {
            check.isFulfiled = true
          })
        }
      },
      openDialogEmployee (items) {
        if (this.userType !== 3) {
          this.$store.dispatch(
            'documentStatus/getDocumentStatusDetail',
            items.item,
          )
          setTimeout(() => {
            this.dialogEmployee = true
          }, 150)
        }
      },
      closeDialogEmployee () {
        this.itemsDialogCheckDocsEmployee = []
        var payload = {}
        payload.timestamp = this.date + '-01'
        payload.customerId = this.customer
        this.$store.dispatch('documentStatus/getDocumentStatus', payload)
        this.dialogEmployee = false
      },
      saveCheckDocs () {
        const info = {
          detail: JSON.stringify(this.convertArrayToObject(this.itemsDialogCheckDocsEmployee, 'detail')),
          isFulfilled: this.dialogCheckDocsEmployee.isFulfilled,
          isRetented: this.dialogCheckDocsEmployee.isRetented,
          isChecked: this.dialogCheckDocsEmployee.isChecked,
          contracted: this.dialogCheckDocsEmployee.contracted,
        }
        this.$store.dispatch('documentStatus/update', {
          id: this.dialogCheckDocsEmployee.id,
          info: info,
        })
        this.closeDialogEmployee()
      },
    },
  }
</script>
<style lang="css">
.v-data-table {
  max-width: 100%;
}
.card-text-contents {
  border: 1px solid #5ca6d2;
  border-radius: 10px;
}
.custom-color-note {
  color: #8da0e4;
  font-size: 16px;
  opacity: 0.9;
}
</style>
